import { FC, Fragment, useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import ImportUploadWithVerification from 'src/components/upload-item/ImportWithValidation'
import { mergeMultipleChanges } from '../../../../components/system/SystemUtil'
import { IMetaField } from '../../../../components/system/system-type-fields/SystemTypeFieldsForm'
import { ImportContext } from '../../../../context/ImportContext/ImportContext'
import { ImportStatus } from '../../../../context/ImportContext/ImportTypes'
import { useJobId } from '../../../../context/ImportContext/hook/useJobId'
import { ProjectContext } from '../../../../context/ProjectContextProvider/ProjectContext'
import { fileContainerImportTemplate } from '../../../../export-templates/FileContainerImportTemplate'
import { fileContainerValidateFromJson } from '../../../../service/FileContainerService'
import { addUserDefinedInfoToImport } from '../../../../utility/exportUtils'
import DataSelectors, {
  DATA_TYPES,
  ImportFiledTypes,
} from '../selectors/DataSelectors'
import { IDocValidationResponse, IFormatData } from '../types'
import DocumentUploadSummery, {
  IDocumentUploadSummery,
} from './DocumentUploadSummery'

interface DocumentImportsProps {
  show: boolean
  close: () => void
  requiredFields?: IMetaField[]
  optionalFields?: IMetaField[]
  folderId: number
  importJobId?: number
}

const DocumentHeaders = [
  DataSelectors.defineDataField('record_id', DATA_TYPES.string),
  DataSelectors.defineDataField('name', DATA_TYPES.string),
  DataSelectors.defineDataField('deadline', DATA_TYPES.date),
  DataSelectors.getDataField(ImportFiledTypes.DOCUMENT_TYPE),
  DataSelectors.getDataField(ImportFiledTypes.RESPONSIBLE),
  DataSelectors.getDataField(ImportFiledTypes.DISCIPLINE),
]

const DocumentImports: FC<DocumentImportsProps> = ({
  show,
  close,
  requiredFields = [],
  optionalFields = [],
  folderId,
  importJobId,
}) => {
  const { t } = useTranslation()
  const [title] = useState(`${t('upload_documents')}`)
  const [jobId, setJobId] = useState<number | undefined>(importJobId)
  const [currentStep, setCurrentStep] = useState<number>()
  const [pollingResponse, setPollingResponse] = useState<any>()

  const { status, data } = useJobId(jobId)
  const projectContext = useContext(ProjectContext)
  const { id: projectId } = projectContext.state.currentProject

  const { addImportTask } = useContext(ImportContext).actions

  useEffect(() => {
    if (status && status !== ImportStatus.IN_PROGRESS) {
      setPollingResponse(data)
      setCurrentStep(status === ImportStatus.SUCCESS ? 2 : 1)
    }
  }, [status, data])

  const formatResponseData = (responseData: any) => {
    const job = responseData.id
    setJobId(job)
    addImportTask(responseData)

    return responseData.job_data as IDocValidationResponse
  }

  const validateMultiple = async (
    newValues: Record<string, string>[],
    validRes: IDocValidationResponse,
  ) => {
    const file_containers = mergeMultipleChanges(
      newValues,
      validRes.file_containers ?? [],
    )
    const res = await fileContainerValidateFromJson(projectId, folderId, {
      file_containers: file_containers,
      job_id: jobId,
    })
    addImportTask(res)
    return res
  }

  const uploadDocumentToRemote = async (file_containers: IFormatData[]) => {
    const res = await fileContainerValidateFromJson(projectId, folderId, {
      file_containers: file_containers,
      job_id: jobId,
    })

    const data = addImportTask(res)

    return data
  }

  if (status === ImportStatus.FAILED) {
    close()
    return <Fragment />
  }

  if (status === ImportStatus.IN_PROGRESS) {
    return <Fragment />
  }

  return (
    <ImportUploadWithVerification
      show={show}
      close={close}
      uploadUrl={`{ORG_URL}/folders/${folderId}/file_containers/validate_for_import_job`}
      importTemplate={addUserDefinedInfoToImport(
        fileContainerImportTemplate,
        requiredFields,
        optionalFields,
      )}
      useDefinedFiled={requiredFields.concat(optionalFields)}
      formatResponseData={formatResponseData}
      validateMultiple={validateMultiple}
      parentId={projectId}
      title={title}
      type={'file_containers'}
      currentStep={currentStep}
      columns={DocumentHeaders}
      responseData={pollingResponse}
      uploadData={uploadDocumentToRemote}
      confirmStep={(uploadRef) => {
        if (!uploadRef) return <span />
        return (
          <DocumentUploadSummery
            summery={uploadRef as IDocumentUploadSummery}
          />
        )
      }}
    />
  )
}

export default DocumentImports
