import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import DocumentTypeService from 'src/document/services/DocumentTypeService'
import { IDocumentType } from 'src/document/types/IFlow'
import { getUploadDocURl } from 'src/service/FileUploadService'
import {
  createImage,
  createImageToMultipleParents,
} from 'src/service/ImageService'
import { IDocument } from 'src/service/OrgTypes'
import Button from 'src/ui-elements/button/Button'
import Input from 'src/ui-elements/input/Input'
import Modal from 'src/ui-elements/modal/Modal'
import ModalFooter from 'src/ui-elements/modal/ModalFooter'
import Textarea from 'src/ui-elements/textarea/Textarea'
import { capFirstLetter } from 'src/utility/utils'
import UploadFile from '../document/UploadFile'
import UploadFileList from '../document/UploadFileList'
import Selector from '../selectors/Selector'

interface IAttachmentFormProps {
  show: boolean
  parentType: string
  parentId?: number
  parentIds?: number[]
  projectId: number
  onClose: (submitted: boolean) => void
}

const AttachmentForm = ({
  show,
  parentType,
  parentId,
  parentIds,
  projectId,
  onClose,
}: IAttachmentFormProps) => {
  const { t } = useTranslation()
  const [title, setTitle] = useState('')
  const [source, setSource] = useState('')
  const [description, setDescription] = useState('')
  const [documents, setDocuments] = useState<IDocument[]>([])
  const [imageErrorMessage, setImageErrorMessage] = useState('')
  const [documentTypes, setDocumentTypes] = useState<IDocumentType[]>([])
  const [documentTypeId, setDocumentTypeId] = useState(0)
  const [selectLoading, setSelectLoading] = useState(false)

  const fetchDocumentTypes = () => {
    setSelectLoading(true)
    DocumentTypeService.getProjectDocumentTypes().then((res) => {
      setDocumentTypes(res)
      setSelectLoading(false)
    })
  }

  const onSubmit = (e: any) => {
    e.preventDefault()
    let error = false
    if (documents.length === 0) {
      setImageErrorMessage(t('upload_image_to_proceed'))
      error = true
    }
    if (!error) {
      const data = {
        name: !!title ? title : undefined,
        document_type_id: documentTypeId > 0 ? documentTypeId : undefined,
        source,
        description,
        documents: documents?.map((document) => ({
          name: document.fileName,
          url: document.fileUrl,
        })),
      }

      if (parentIds && !parentId) {
        createImageToMultipleParents(
          data,
          parentType,
          parentIds,
          projectId,
        ).then(() => {
          onClose(true)
        })
      }

      if (!parentIds && parentId) {
        createImage(data, parentType, parentId, projectId).then(() => {
          onClose(true)
        })
      }
    }
  }

  const updateUploadedFiles = (docs: IDocument[]) => {
    setDocuments(docs)
  }

  const filesUploaded = (docs: IDocument[]) => {
    setDocuments(documents.concat(docs))
    setImageErrorMessage('')
  }

  const onDocumentTypeChange = (id: number) => {
    setDocumentTypeId(id)
  }

  const onSourceChange = (e: any) => {
    setSource(e.target.value)
  }

  const onCloseModal = () => {
    setTitle('')
    setDescription('')
    setDocumentTypeId(0)
    setSource('')
    setDocuments([])
    setImageErrorMessage('')
    onClose(false)
  }
  return (
    <Modal
      show={show}
      title={t('attachment')}
      closeModal={onCloseModal}
      size={'w-1/2'}
      maxWidth={800}
    >
      <div className="w-full">
        <Input
          label={t('title')}
          block={true}
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          required={false}
          autoFocus={true}
          errorMessage={''}
        />
        <Selector
          items={documentTypes}
          selectedItemId={documentTypeId}
          onSelect={onDocumentTypeChange}
          onOpenSelectFunction={fetchDocumentTypes}
          label={t('document_category')}
          dataFields={['name']}
          required={false}
          loading={selectLoading}
          fontSize={'sm'}
          fontWeight={'bold'}
          userSelector={false}
        />
        <div className="w-full">
          <Input
            block={true}
            label={t('source')}
            onChange={onSourceChange}
            value={source}
          />
        </div>
        <Textarea
          label={t('description')}
          value={description}
          isValid={false}
          autoFocus={false}
          onChange={(e) => setDescription(e.target.value)}
          block={true}
        />
        <div className="w-full my-4">
          {documents && documents.length > 0 && (
            <UploadFileList
              documents={documents}
              updateDocuments={updateUploadedFiles}
            />
          )}
        </div>
        {imageErrorMessage && (
          <p className="flex items-center text-red-600 m-2  text-sm font-normal">
            {imageErrorMessage}
          </p>
        )}

        <div className="w-full my-8">
          <UploadFile
            uploadedDocuments={filesUploaded}
            uploadUrl={getUploadDocURl(projectId, parentType)}
          />
        </div>
      </div>
      <ModalFooter>
        <Button onClick={() => onClose(false)}>
          {capFirstLetter(t('cancel'))}
        </Button>
        <Button type={Button.ButtonType.PRIMARY} onClick={onSubmit}>
          {capFirstLetter(t('save'))}
        </Button>
      </ModalFooter>
    </Modal>
  )
}

export default AttachmentForm
