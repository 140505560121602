import { RouteComponentProps } from 'react-router-dom'
import ContractTree from 'src/components/ContractDisciplineTree/ContractTree'
import DisciplineTree from 'src/components/ContractDisciplineTree/DisciplineTree'
import TagList from 'src/components/Tag/TagList'
import PhasesList from 'src/components/phase/PhasesList'
import PPUGoals from 'src/components/ppu-goals/PPUGoals'
import MainProcessList from 'src/components/process/main-process/MainProcessList'
import ProjectSharingPage from 'src/components/project/SharingPage'
import RolesList from 'src/components/role/RoleList'
import TabView, { ITabs } from 'src/components/tab-view/TabView'
import TeamList from 'src/components/team/TeamList'
import ProjectUsersList from 'src/components/user/ProjectUsersList'
import UserConfigPage from 'src/components/user/UserConfigPage'
import DocumentTypeList from 'src/document/components/DocumentType/DocumentTypeList'
import { useMultipleKeysTranslation } from 'src/service/useMultipleKeysTranslation'

import RiskConfigPage from '../risk/RiskConfigPage'

interface IProjectPage {
  projectId: number
  projectAdmin: boolean
  reload: () => void
  routeProps?: RouteComponentProps<{
    contractId?: string
    disciplineId?: string
  }>
}

const ProjectPage = ({
  projectId,
  projectAdmin,
  reload,
  routeProps,
}: IProjectPage) => {
  const { t } = useMultipleKeysTranslation()

  const tabs: ITabs = {
    users: {
      title: t('users'),
      show: true,
      content: projectAdmin ? (
        <UserConfigPage
          projectId={projectId}
          reload={reload}
          projectAdmin={projectAdmin}
        />
      ) : (
        <ProjectUsersList
          projectId={projectId}
          reload={reload}
          projectAdmin={projectAdmin}
        />
      ),
    },
    contracts: {
      title: t('contracts'),
      show: projectAdmin,
      content: <ContractTree projectId={projectId} />,
    },
    disciplines: {
      title: t('disciplines'),
      show: projectAdmin,
      content: (
        <DisciplineTree
          projectId={projectId}
          disciplineId={Number(routeProps?.match?.params?.disciplineId)}
        />
      ),
    },
    roles: {
      title: t('roles'),
      show: projectAdmin,
      content: <RolesList projectId={projectId} />,
    },
    link_sharing: {
      title: t('link_sharing'),
      show: projectAdmin,
      content: (
        <ProjectSharingPage projectId={projectId} projectAdmin={projectAdmin} />
      ),
    },
    phases: {
      title: t('phases'),
      show: projectAdmin,
      content: <PhasesList projectId={projectId} />,
    },
    teams: {
      title: t('teams'),
      show: projectAdmin,
      content: <TeamList projectId={projectId} />,
    },
    risks: {
      title: t('risks'),
      show: projectAdmin,
      content: <RiskConfigPage projectId={projectId} />,
    },
    main_processes: {
      title: t('main_processes'),
      show: projectAdmin,
      content: <MainProcessList projectId={projectId} />,
    },
    types: {
      title: t('types'),
      show: projectAdmin,
      content: <TagList projectId={projectId} />,
    },
    reliability_ppu_goals: {
      title: t('reliability_ppu_goals'),
      show: projectAdmin,
      content: <PPUGoals />,
    },
    document_types: {
      title: t('document_categories'),
      show: projectAdmin,
      content: <DocumentTypeList />,
    },
  }

  return <TabView tabs={tabs} />
}

export default ProjectPage
