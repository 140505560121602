import Delete from '@icons/delete.svg'
import RestartAlt from '@icons/restart_alt.svg'
import Save from '@icons/save.svg'
import Table from '@icons/table.svg'
import TableView from '@icons/table_view.svg'
import Add from '@material-symbols/svg-500/rounded/add.svg'
import { Table as ReactTable } from '@tanstack/react-table'
import { t } from 'i18next'
import { useState } from 'react'
import { SubmitHandler } from 'react-hook-form'
import useProjectId from 'src/components/hooks/useProjectId'
import { queryClient } from 'src/query/client'
import {
  useDeleteSavedTableConfig,
  useMutateSavedTableConfigs,
  useClearTableConfig,
  savedTableConfigQueryKey,
} from 'src/query/tableConfig'
import TableConfigFormModal from 'src/ui-elements/Table/TableConfigFormModal'
import {
  SavedTableConfig,
  TableKeeperState,
} from 'src/ui-elements/Table/useTable'
import Button from 'src/ui-elements/button/Button'
import ButtonWithDropdownMenu from 'src/ui-elements/button/ButtonWithDropdownMenu'
import ButtonWithDropdownMenuMultipleActions from 'src/ui-elements/button/ButtonWithDropdownMenuMultipleActions'

type ITableConfigButtonProps<T> = {
  table: ReactTable<T>
  tableName: string
  savedTableConfigs: SavedTableConfig[]
}

export interface ITableConfigProps {
  name: string
}

const TableConfigButtons = <T extends { id: number | string }>({
  tableName,
  savedTableConfigs,
  table,
}: ITableConfigButtonProps<T>) => {
  const [showCreateConfigModal, setShowCreateConfigModal] = useState(false)
  const projectId = useProjectId()

  const { mutate: deleteConfig } = useDeleteSavedTableConfig(tableName)
  const { mutate: clearTableConfig } = useClearTableConfig(tableName)
  const { mutate: mutateConfig } = useMutateSavedTableConfigs(tableName)
  const { mutate: mutateConfigWithCallback } = useMutateSavedTableConfigs(
    tableName,
    () => {
      setShowCreateConfigModal(false)
      queryClient.invalidateQueries({
        queryKey: savedTableConfigQueryKey(projectId, tableName),
      })
    },
  )

  const copyCurrentTableKeeperState = () => {
    return {
      columnVisibility: table.getState().columnVisibility,
      sorting: table.getState().sorting,
      filters: table.getState().columnFilters,
      columnOrdering: table.getState().columnOrder,
      columnSizing: table.getState().columnSizing,
    } as TableKeeperState
  }

  const setNewActiveTableConfig = (config: SavedTableConfig) => {
    const currentActive = savedTableConfigs.find((c) => c.active)
    if (currentActive && currentActive.name !== config.name) {
      mutateConfig({ ...currentActive, active: false })
    }
    mutateConfigWithCallback({ ...config, active: true })
    table.setColumnVisibility(config.config.columnVisibility ?? {})
    table.setSorting(config.config.sorting ?? [])
    table.setColumnFilters(config.config.filters ?? [])
    table.setColumnOrder(config.config.columnOrdering ?? [])
    table.setColumnSizing(config.config.columnSizing ?? {})
  }

  const saveTableConfig: SubmitHandler<ITableConfigProps> = async ({
    name,
  }) => {
    const config = copyCurrentTableKeeperState()
    const newConfig = {
      config: config,
      name: name,
      active: true,
    }
    const currentActive = savedTableConfigs.find((c) => c.active)
    if (currentActive) mutateConfig({ ...currentActive, active: false })
    mutateConfigWithCallback(newConfig)
  }

  const overrideCurrentActiveConfig = () => {
    const currentActive = savedTableConfigs.find((c) => c.active)
    const config = copyCurrentTableKeeperState()
    if (currentActive) {
      mutateConfigWithCallback({
        config: config,
        name: currentActive.name,
        active: true,
      })
    }
  }

  const clearTableConfigurations = () => {
    table.resetSorting()
    table.resetColumnFilters()
    table.resetColumnSizing()
    table.resetColumnVisibility()
    table.resetColumnOrder()
    clearTableConfig(tableName)
    const currentActive = savedTableConfigs.find((c) => c.active)
    if (currentActive) {
      mutateConfig({ ...currentActive, active: false })
    }
  }

  return (
    <>
      <div className="flex items-center">
        <ButtonWithDropdownMenu
          type={Button.ButtonType.SECONDARY}
          title={`${t('save')} ${t('table_configuration')}`}
          items={[
            {
              icon: <Add className="text-md mr-1" />,
              title: `${t('create_new')} ${t('table_configuration')}`,
              onClick: () => setShowCreateConfigModal(true),
            },
            {
              icon: <Save className="text-md mr-1" />,
              title: t('override_current_table_configuration'),
              onClick: () => overrideCurrentActiveConfig(),
            },
            {
              icon: <RestartAlt className="text-md mr-1" />,
              title: `${t('clear_table_configuration_for_this_table')}`,
              onClick: () => clearTableConfigurations(),
            },
          ]}
        >
          <Table className={'text-xl'} />
        </ButtonWithDropdownMenu>
        <ButtonWithDropdownMenuMultipleActions
          disabled={savedTableConfigs.length < 1}
          title={`${t('edit')} ${t('table_configurations')}`}
          items={
            savedTableConfigs.length > 0
              ? savedTableConfigs.map((c) => ({
                  icon: <Delete className={'fill-red text-sm'} />,
                  active: c.active,
                  onClickIcon: () => deleteConfig(c.name),
                  title: c.name,
                  onClick: () => setNewActiveTableConfig(c),
                }))
              : []
          }
        >
          <TableView className="text-xl" />
        </ButtonWithDropdownMenuMultipleActions>
      </div>
      {showCreateConfigModal && (
        <TableConfigFormModal
          tableConfigs={savedTableConfigs}
          closeModal={() => setShowCreateConfigModal(false)}
          showModal={showCreateConfigModal}
          onSave={saveTableConfig}
        />
      )}
    </>
  )
}

export default TableConfigButtons
