export const fileContainerImportTemplate = {
  name: 'file_container_import_template',
  displayName: 'file_container_import_template',
  columns: [
    'document_number',
    'document_name',
    'document_category',
    'deadline',
    'responsible',
    'discipline',
    'document_type',
  ],
  templateColumns: ['name', 'required', 'value', 'description', 'example'],
  template: [
    {
      name: 'document_number',
      required: false,
      value: 'text',
      description: 'document_number',
      example: 'XXX.XXXX.XXXX',
    },
    {
      name: 'document_name',
      required: false,
      value: 'text',
      description: 'document_name',
      example: '',
    },
    {
      name: 'document_type',
      required: true,
      value: 'text',
      description: 'document_type',
      example: '',
    },
    {
      name: 'deadline',
      required: false,
      value: 'date_mm_dd_yyyy',
      description: 'deadline',
      example: '',
    },
    {
      name: 'responsible',
      required: false,
      value: 'text',
      description: 'responsible',
      example: 'Ola Nordmann',
    },
    {
      name: 'discipline',
      required: false,
      value: 'text',
      description: 'discipline',
      example: 'RIV',
    },
    {
      name: 'test_document_type',
      required: false,
      value: 'text',
      description: 'test_document_type',
      example: 'Systemskjema',
    },
  ],
}
