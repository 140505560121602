import { useMutation, useQuery } from '@tanstack/react-query'
import useProjectId from 'src/components/hooks/useProjectId'
import {
  clearTableConfigForTable,
  deleteSavedTableConfig,
  getSavedTableConfigs,
  getTable,
  setSavedTableConfig,
  setTableConfig,
} from 'src/service/TableKeeperService'
import { queryClient } from './client'

export const tableConfig = 'table-Config'
export const savedConfigs = 'saved-Configs'

export const tableConfigQueryKey = (
  projectId: number,
  tableName: string,
  projectLevel: boolean,
) => [tableConfig, projectId, tableName, projectLevel]

export const savedTableConfigQueryKey = (
  projectId: number,
  tableName: string,
) => [savedConfigs, projectId, tableName]

export const useTableConfig = <T = unknown>(
  tableName: string,
  projectLevel: boolean = false,
) => {
  const projectId = useProjectId()

  return useQuery({
    queryKey: tableConfigQueryKey(projectId, tableName, projectLevel),
    queryFn: () => getTable<T>(projectId, tableName, projectLevel),
  })
}

export const useSavedTableConfigs = <T = unknown>(tableName: string) => {
  const projectId = useProjectId()
  return useQuery({
    queryKey: savedTableConfigQueryKey(projectId, tableName),
    queryFn: () => getSavedTableConfigs<T>(projectId, tableName),
  })
}

export const useMutateTableConfig = <T = unknown>(tableName: string) => {
  const projectId = useProjectId()

  return useMutation({
    mutationKey: [tableConfig, projectId, tableName],
    mutationFn: (config: T) =>
      setTableConfig(projectId, tableName, { data: config }),
  })
}

export const useMutateSavedTableConfigs = <T = unknown>(
  tableName: string,
  onSuccessCallback?: () => void,
) => {
  const projectId = useProjectId()
  return useMutation({
    mutationKey: [savedConfigs, projectId, tableName],
    mutationFn: (config: T) =>
      setSavedTableConfig(projectId, tableName, config),
    onSuccess: () => {
      onSuccessCallback?.()
    },
  })
}

export const useDeleteSavedTableConfig = (tableName: string) => {
  const projectId = useProjectId()
  return useMutation({
    mutationKey: [savedConfigs, projectId, tableName],
    mutationFn: (configName: string) =>
      deleteSavedTableConfig(projectId, tableName, configName),
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: savedTableConfigQueryKey(projectId, tableName),
      })
    },
  })
}

export const useClearTableConfig = (tableName: string) => {
  const projectId = useProjectId()
  return useMutation({
    mutationKey: [tableConfig, projectId, tableName],
    mutationFn: (tableName: string) =>
      clearTableConfigForTable(projectId, tableName),
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: savedTableConfigQueryKey(projectId, tableName),
      })
    },
  })
}

export const useTableKeeper = <T = unknown>(tableName: string) => {
  const tableConfig = useTableConfig<T>(tableName)
  const mutateTable = useMutateTableConfig<T>(tableName)

  return {
    data: tableConfig.data,
    isLoading: tableConfig.isLoading,
    isPending: tableConfig.isPending,
    mutate: mutateTable,
  }
}
