import * as React from 'react'
import { useMultipleKeysTranslation } from 'src/service/useMultipleKeysTranslation'
import Tooltip from 'src/ui-elements/tooltip/Tooltip'

interface IInlineInputLabelComponent {
  label: string
  labelWidth?: string
  labelTextSize?: string
  disableTooltip?: boolean
}

const InlineInputLabelComponent: React.FC<IInlineInputLabelComponent> = ({
  labelWidth = 'min-w-36',
  label,
  labelTextSize = 'text-sm',
  disableTooltip = false,
}) => {
  const { t } = useMultipleKeysTranslation()

  return (
    <Tooltip show={!disableTooltip} message={t(label)}>
      <div
        className={`${labelWidth} ${labelTextSize} flex flex-col overflow-hidden text-ellipsis whitespace-nowrap font-semibold p-1 text-[#515558]`}
      >
        {t(label)}
      </div>
    </Tooltip>
  )
}

export default InlineInputLabelComponent
