import { PropsWithChildren, FC } from 'react'
import FdvuSubcontractorTopMenu from './top-menu/FdvuSubcontractorTopMenu'

const SubcontractorWrapper: FC<PropsWithChildren> = ({ children }) => {
  return (
    <>
      <FdvuSubcontractorTopMenu />
      <div className="overflow-auto h-[calc(100vh_-_4rem)]">
        <div id="navigation-row" />
        {children}
      </div>
    </>
  )
}

export default SubcontractorWrapper
